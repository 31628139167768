import { WebpPictureProps } from "@/types/components/webPicture/webPicture.d";
import { HTMLAttributes, MouseEventHandler, useEffect, useState } from "react";

const WebpPicture = ({ src, alt, className = '', lazyLoad = true, ...props }: WebpPictureProps) => {
    const [srcWebp, setSrcWebp] = useState<string>('');
    const [sourceExtension, setSourceExtension] = useState<string|undefined>('');
    const [isWebpSupported, setIsWebpSupported] = useState<boolean>(false);

    const getFileExtension = (src: string): string => {
        const parts = src.split('.');
        return parts[parts.length - 1];
    }

    const replaceFileExtension = (src: string, extension: string): string => {
        const parts = src.split('.');
        parts[parts.length - 1] = extension;
        return parts.join('.');
    }
    
    useEffect(() => {
        setSourceExtension(getFileExtension(src));
        setSrcWebp(replaceFileExtension(src, 'webp'));
    }, [srcWebp]);

    useEffect(() => {
        const elem = document.createElement('canvas');
        
        if (!!(elem.getContext && elem.getContext('2d'))) {
            setIsWebpSupported(elem.toDataURL('image/webp').indexOf('data:image/webp') === 0)
        }else{
            setIsWebpSupported(false)
        }
    }, [isWebpSupported]);

    return (
        <picture {...props} className="w-full">
            {isWebpSupported
                ?
                <img loading={lazyLoad ? 'lazy' : 'eager'} src={`${encodeURI(srcWebp)}`} alt={alt} className={className}/>
                :
                <img loading={lazyLoad ? 'lazy' : 'eager'} src={`${encodeURI(src)}`} alt={alt} className={className}/>
            }
        </picture>
    )
}

export default WebpPicture;